<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <h3>
          پرونده {{ userData.user.name }} ({{ userData.user.role }})
          {{ userData.user.natCode ? "/ " + userData.user.natCode : "" }}
        </h3></v-card-title
      >
      <v-tabs
        background-color="transparent"
        vertical
        v-model="tab"
        :style="
          deviceType == 'mobile'
            ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
            : ''
        "
      >
        <v-tab class="tab-title first-tab-item-br"> اطلاعات پزشک </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ویرایش اطلاعات پزشک
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          درآمد حاصل از خدمات
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          خدمات
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ساعات کارکرد
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          زمان‌های آزاد پزشک
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >لیست مرخصی‌ها</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >پرداختی‌ها‌ی پزشک</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >دریافتی‌ها‌ی پزشک</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >تسویه حساب</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >فیش حقوقی</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >معاینات بدو استخدام</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >بایگانی فایل‌ها</v-tab
        >
        <v-tab class="tab-title last-tab-item-br">تغییر رمز عبور</v-tab>

        <v-tabs-items v-model="tab">
          <!-- اطلاعات پزشک -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- edit info part has been componented -->
              <person-info :job="'doctor'" :userData="userData.user" />
            </v-card>
          </v-tab-item>

          <!--  ویرایش اطلاعات پزشک -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- edit info part has been componented -->
              <edit-person
                :job="'doctor'"
                @getInfo="showDocInfo()"
                :userData="userData.user"
              />
            </v-card>
          </v-tab-item>
          <!-- درآمد حاصل از خدمات -->
          <v-tab-item v-observe-visibility="visibilityChanged">
            <v-card class="pa-5" style="min-height: 700px">
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="dateFrom">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="dateFrom"
                      label=" تاریخ از "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="dateFrom"
                    element="dateFrom"
                    color="#00a7b7"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="dateTo">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="dateTo"
                      label=" تاریخ تا "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="dateTo"
                    element="dateTo"
                    color="#00a7b7"
                  />
                </v-col>
              </v-row>
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="timeFrom">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="schedule"
                      v-model="timeFrom"
                      label=" ساعت از "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="timeFrom"
                    element="timeFrom"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="timeTo">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="schedule"
                      v-model="timeTo"
                      label=" ساعت تا "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="timeTo"
                    element="timeTo"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    class="submit-btn primary-btn"
                    style="float: right"
                    @click="
                      docPercent();
                      startDate = dateFrom;
                      endDate = dateTo;
                    "
                    :disabled="busyDocPercent"
                    >اعمال</v-btn
                  >
                </v-col>
              </v-row>
              <hr />
              <v-row class="justify-content-center mb-2 my-5">
                <v-progress-circular
                  v-if="!isMounted"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>

              <div v-if="isMounted">
                <template>
                  <v-expansion-panels multiple v-model="opens">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="font-weight-bold primary--text"
                      >
                        خدمات پزشک ( {{ Items.length }} مورد )
                      </v-expansion-panel-header>

                      <v-expansion-panel-content class="my-3">
                        <v-row style="margin: 10px">
                          <v-col style="float: left">
                            <v-btn
                              class="secondary-btn"
                              @click="print('docPDF')"
                              :disabled="!Items.length"
                            >
                              پرینت خدمات
                            </v-btn>
                          </v-col>
                          <v-col>
                            <h6 class="text-center">
                              <strong
                                >{{
                                  monthDict[moment(startDate).format("MM") - 1]
                                }}
                                ماه
                                {{ moment(startDate).format("YYYY") }}
                                <span v-if="!yearMonthComputed">
                                  تا
                                  {{
                                    monthDict[moment(endDate).format("MM") - 1]
                                  }}
                                  ماه
                                  {{ moment(endDate).format("YYYY") }}
                                </span>
                              </strong>
                            </h6>
                          </v-col>
                          <v-col style="float: right">
                            <v-btn
                              style="float: left"
                              class="secondary-btn"
                              @click="$refs.docServicesExcel.$el.click()"
                              :disabled="!Items.length"
                            >
                              دریافت اکسل خدمات</v-btn
                            >
                            <vue-excel-xlsx
                              :data="Items"
                              ref="docServicesExcel"
                              v-show="false"
                              :columns="
                                excelFields.map((x) => {
                                  return {
                                    label: x.label,
                                    field: x.key,
                                  };
                                })
                              "
                              :filename="
                                'خدمات دکتر ' +
                                userData.user.name +
                                ' از ' +
                                dateFrom +
                                ' تا ' +
                                dateTo
                              "
                              :sheetname="currentDate"
                            >
                              دریافت اکسل
                            </vue-excel-xlsx>
                          </v-col>
                        </v-row>
                        <div id="docPDF" v-show="false">
                          <table>
                            <thead>
                              <v-row class="ma-3">
                                <v-col>
                                  <h3>
                                    نام پزشک: دکتر {{ userData.user.name }}
                                  </h3>
                                </v-col>
                                <v-col>
                                  <v-row>
                                    <v-col>
                                      <h5 style="text-align: center">
                                        از: {{ dateFrom }}
                                      </h5>
                                    </v-col>
                                    <v-col>
                                      <h5 style="text-align: center">
                                        تا: {{ dateTo }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col>
                                  <h5 class="text-center">
                                    <strong
                                      >{{
                                        monthDict[
                                          moment(startDate).format("MM") - 1
                                        ]
                                      }}
                                      ماه
                                      {{ moment(startDate).format("YYYY") }}
                                      <span v-if="!yearMonthComputed">
                                        تا
                                        {{
                                          monthDict[
                                            moment(endDate).format("MM") - 1
                                          ]
                                        }}
                                        ماه
                                        {{ moment(endDate).format("YYYY") }}
                                      </span>
                                    </strong>
                                  </h5>
                                </v-col>
                              </v-row>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <b-table
                                    responsive
                                    show-empty
                                    :fields="Fields"
                                    :items="Items"
                                    empty-text="رکوردی برای نمایش وجود ندارد"
                                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                                    :busy="Busy"
                                    :filter="Filter"
                                  >
                                    <template v-slot:head()="data">
                                      <div
                                        style="
                                          text-align: center;
                                          vertical-align: middle;
                                        "
                                      >
                                        {{ data.label }}
                                      </div>
                                    </template>
                                    <template v-slot:cell()="data">
                                      <div
                                        style="
                                          text-align: center;
                                          vertical-align: middle;
                                        "
                                      >
                                        {{ data.value }}
                                      </div>
                                    </template>
                                    <template v-slot:cell(index)="data">
                                      <div
                                        style="
                                          text-align: center;
                                          vertical-align: middle;
                                        "
                                      >
                                        {{ data.index + 1 }}
                                      </div>
                                    </template>
                                    <template v-slot:cell(share)="data">
                                      <div
                                        style="
                                          text-align: center;
                                          vertical-align: middle;
                                        "
                                      >
                                        {{
                                          Number(data.value).toLocaleString()
                                        }}
                                      </div>
                                    </template>
                                    <div
                                      slot="table-busy"
                                      class="text-center primary--text my-2"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        class="align-middle"
                                      ></v-progress-circular>
                                    </div>
                                  </b-table>
                                  <hr />

                                  <h5 class="text-success mb-3">
                                    <strong
                                      >مجموع سهم پزشک:
                                      {{
                                        Items.map((x) => x.share)
                                          .reduce(
                                            (a, b) => parseInt(a) + parseInt(b),
                                            0
                                          )
                                          .toLocaleString()
                                      }}</strong
                                    >
                                    ریال
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- pdf  -->
                        </div>
                        <!-- pdf -->
                        <b-table
                          responsive
                          show-empty
                          :fields="Fields"
                          :items="Items"
                          empty-text="رکوردی برای نمایش وجود ندارد"
                          empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                          :busy="Busy"
                          :filter="Filter"
                          @filtered="onFiltered"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(index)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                            </div>
                          </template>
                          <template v-slot:cell(share)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <div
                            slot="table-busy"
                            class="text-center primary--text my-2"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              class="align-middle"
                            ></v-progress-circular>
                          </div>
                        </b-table>
                        <!--<v-pagination
                          v-model="CurrentPage"
                          :length="Math.ceil(TotalRows / PerPage)"
                          :total-visible="5"
                          prev-icon="arrow_back"
                          next-icon="arrow_forward"
                        ></v-pagination>-->
                        <!--<v-select
                          label="تعداد در هر صفحه:"
                          style="width: 150px"
                          v-model="PerPage"
                          :items="perPageOptions"
                          item-text="text"
                          item-value="value"
                        ></v-select>-->
                        <hr />

                        <h5 class="text-success mb-3">
                          <strong
                            >مجموع سهم پزشک:
                            {{
                              Items.map((x) => x.share)
                                .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                                .toLocaleString()
                            }}</strong
                          >
                          ریال
                        </h5>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="font-weight-bold primary--text"
                      >
                        درآمد پزشک ( کارکرد کل :
                        {{
                          income
                            ? Number(
                                income.allTotal.totalTotalIncome
                              ).toLocaleString()
                            : "-"
                        }}
                        )
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="my-3">
                        <v-row style="margin: 10px">
                          <v-col>
                            <v-btn
                              class="secondary-btn"
                              @click="print('docIncomePDF')"
                              :disabled="!income.shift.length"
                            >
                              پرینت درآمد
                            </v-btn>
                          </v-col>
                          <v-col>
                            <h6 class="text-center">
                              <strong
                                >{{
                                  monthDict[moment(startDate).format("MM") - 1]
                                }}
                                ماه
                                {{ moment(startDate).format("YYYY") }}
                                <span v-if="!yearMonthComputed">
                                  تا
                                  {{
                                    monthDict[moment(endDate).format("MM") - 1]
                                  }}
                                  ماه
                                  {{ moment(endDate).format("YYYY") }}
                                </span>
                              </strong>
                            </h6>
                          </v-col>
                        </v-row>
                        <div
                          id="docIncomePDF"
                          style="font-family: B Nazanin"
                          v-show="false"
                        >
                          <!-- NOTE pdf  -->
                          <v-row class="ma-3">
                            <v-col>
                              <h3>نام پزشک: دکتر {{ userData.user.name }}</h3>
                            </v-col>
                            <v-col>
                              <v-row>
                                <v-col>
                                  <h5 style="text-align: center">
                                    از: {{ dateFrom }}
                                  </h5>
                                </v-col>
                                <v-col>
                                  <h5 style="text-align: center">
                                    تا: {{ dateTo }}
                                  </h5>
                                </v-col>
                                <v-col>
                                  <h5 class="text-center">
                                    <strong
                                      >{{
                                        monthDict[
                                          moment(startDate).format("MM") - 1
                                        ]
                                      }}
                                      ماه
                                      {{ moment(startDate).format("YYYY") }}
                                      <span v-if="!yearMonthComputed">
                                        تا
                                        {{
                                          monthDict[
                                            moment(endDate).format("MM") - 1
                                          ]
                                        }}
                                        ماه
                                        {{ moment(endDate).format("YYYY") }}
                                      </span>
                                    </strong>
                                  </h5>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <b-table
                            responsive
                            show-empty
                            :fields="incomeFields"
                            :items="income.shift"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                            empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                            :busy="Busy"
                            :filter="Filter"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  typeof data.value === "number"
                                    ? Number(data.value).toLocaleString()
                                    : data.value
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.index + 1 }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <br />
                          <v-row>
                            <v-col>
                              <h5><strong>جمع کل: </strong></h5>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >ویزیت:
                                  {{
                                    Number(
                                      income.allTotal.totalVisitIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >خدمات:
                                  {{
                                    Number(
                                      income.allTotal.totalBaliniIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >کارکرد:
                                  {{
                                    Number(
                                      income.allTotal.totalTotalIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                          </v-row>
                          <hr />

                          <b-table
                            responsive
                            show-empty
                            :fields="paymentFields"
                            :items="income.payments"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                            empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                            :busy="Busy"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index + PerPage * (CurrentPage - 1) + 1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(payment)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                        </div>
                        <!-- pdf -->
                        <h4 class="primary--text text-center">
                          <strong>جمع کل </strong>
                        </h4>

                        <v-row
                          style="margin: 20px 10px"
                          v-if="userData.user.expertise == 'عمومی'"
                        >
                          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary"
                                  >mdi-hospital-box-outline</v-icon
                                >
                                ویزیت :
                                {{
                                  Number(
                                    income.allTotal.totalVisitIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary"
                                  >mdi-hospital-building</v-icon
                                >
                                خدمات پرستاری :
                                {{
                                  Number(
                                    income.allTotal.totalBaliniIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <div class="common-text common-div">
                              <v-icon color="primary">mdi-doctor</v-icon>
                              <strong>
                                خدمات پزشک :
                                {{
                                  Number(
                                    income.allTotal.totalSurgeryIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary">mdi-cash-plus</v-icon>
                                کارکرد :
                                {{
                                  Number(
                                    income.allTotal.totalTotalIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row
                          style="margin: 20px 10px"
                          v-if="userData.user.expertise == 'دندانپزشک'"
                        >
                          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary"
                                  >mdi-cash-multiple</v-icon
                                >
                                کارکرد دندانپزشکی :
                                {{
                                  Number(
                                    income.allTotal.totalDentistryIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary">mdi-cash-plus</v-icon>
                                کارکرد :
                                {{
                                  Number(
                                    income.allTotal.totalTotalIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row
                          style="margin: 20px 10px"
                          v-if="
                            userData.user.expertise != 'عمومی' &&
                            userData.user.expertise != 'دندانپزشک'
                          "
                        >
                          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary"
                                  >mdi-hospital-box-outline</v-icon
                                >
                                ویزیت :
                                {{
                                  Number(
                                    income.allTotal.totalExpertVisitIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>

                          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary"
                                  >mdi-hospital-building</v-icon
                                >
                                خدمات :
                                {{
                                  Number(
                                    income.allTotal.totalExpertClinicalIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <div class="common-text common-div">
                              <strong>
                                <v-icon color="primary">mdi-cash-plus</v-icon>
                                کارکرد :
                                {{
                                  Number(
                                    income.allTotal.totalTotalIncome
                                  ).toLocaleString()
                                }}
                              </strong>
                            </div>
                          </v-col>
                        </v-row>
                        <b-table
                          responsive
                          show-empty
                          :fields="incomeFields"
                          :items="income.shift"
                          empty-text="رکوردی برای نمایش وجود ندارد"
                          empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                          :busy="Busy"
                          :filter="Filter"
                          @filtered="incomeonFiltered"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{
                                typeof data.value === "number"
                                  ? Number(data.value).toLocaleString()
                                  : data.value
                              }}
                            </div>
                          </template>
                          <template v-slot:cell(index)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{
                                data.index +
                                incomePerPage * (incomeCurrentPage - 1) +
                                1
                              }}
                            </div>
                          </template>
                          <div
                            slot="table-busy"
                            class="text-center primary--text my-2"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              class="align-middle"
                            ></v-progress-circular>
                          </div>
                        </b-table>
                        <!--
                        <v-pagination
                          v-model="incomeCurrentPage"
                          :length="Math.ceil(incomeTotalRows / incomePerPage)"
                          :total-visible="5"
                          prev-icon="arrow_back"
                          next-icon="arrow_forward"
                        ></v-pagination>-->
                        <!--<v-select
                          label="تعداد در هر صفحه:"
                          style="width: 150px"
                          v-model="incomePerPage"
                          :items="perPageOptions"
                          item-text="text"
                          item-value="value"
                        ></v-select>-->

                        <hr />
                        <b-table
                          responsive
                          show-empty
                          :fields="paymentFields"
                          :items="income.payments"
                          empty-text="رکوردی برای نمایش وجود ندارد"
                          empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                          :busy="Busy"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(index)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                            </div>
                          </template>
                          <template v-slot:cell(payment)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <div
                            slot="table-busy"
                            class="text-center primary--text my-2"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              class="align-middle"
                            ></v-progress-circular>
                          </div>
                        </b-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </div>
            </v-card>
          </v-tab-item>
          <!-- خدمات -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- edit info part has been componented -->
              <person-activity :userId="userId" />
            </v-card>
          </v-tab-item>
          <!-- ساعات کارکرد -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- edit info part has been componented -->
              <person-activity-time :userId="userId" />
            </v-card>
          </v-tab-item>

          <!-- زمان‌های آزاد -->
          <v-tab-item>
            <v-card class="pa-5">
              <v-card-title>
                <h4>مدیریت زمان‌های آزاد پزشک</h4>
                <v-spacer></v-spacer>
                <v-btn class="primary-btn" @click="addFreeDialog = true"
                  >افزودن زمان آزاد</v-btn
                >
              </v-card-title>
              <v-row>
                <v-col cols="12" md="5">
                  <div
                    class="cyan lighten-5 pa-5 ms-6"
                    style="border-radius: 20px"
                  >
                    <div>
                      <v-icon small class="green--text text--darken-4"
                        >mdi-circle</v-icon
                      >
                      <span class="h4 grey--text text--darken-3">
                        کلیه زمان‌ها در این روز آزاد هستند.
                      </span>
                    </div>
                    <div class="my-3">
                      <v-icon small class="orange--text">mdi-circle</v-icon>
                      <span class="h4 grey--text text--darken-3">
                        در این روز کلیه زمان‌‌ها پر نشده‌اند.
                      </span>
                    </div>
                    <div>
                      <v-icon small class="red--text">mdi-circle</v-icon>
                      <span class="h4 grey--text text--darken-3">
                        کلیه زمان‌ها در این روز پر هستند.
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="px-4" style="justify-content: space-between">
                <v-col lg="5" md="12" sm="12" cols="12">
                  <div class="calendar">
                    <div
                      class="header d-flex justify-content-center align-items-center"
                    >
                      <div
                        v-if="year != currentYear || month != currentMonth"
                        class="calendar-btn"
                      >
                        <v-btn
                          elevation="0"
                          height="30"
                          class="white fs-xsmall pa-0"
                          @click="showNow"
                          ><span class="c-primary">اکنون</span></v-btn
                        >
                      </div>
                      <v-row class="px-4">
                        <v-col class="text-center">
                          <span
                            id="yearPicker"
                            class="year white--text mb-1 pointer h1"
                            ><v-icon class="me-1 white--text"
                              >fa fa-pencil-square-o</v-icon
                            >
                            {{ year }}
                          </span>
                        </v-col>
                      </v-row>
                      <date-picker
                        element="yearPicker"
                        v-model="year"
                        type="year"
                        :auto-submit="true"
                        :min="currentYear"
                        @change="getNumberOfDays()"
                      >
                      </date-picker>
                    </div>
                    <div class="month py-2">
                      <span
                        id="monthPicker"
                        class="mb-0 pointer primary--text h4"
                      >
                        <v-icon class="me-1 primary--text"
                          >fa fa-pencil-square-o</v-icon
                        >
                        {{ persianMonthName(month) }}
                      </span>
                    </div>
                    <date-picker
                      element="monthPicker"
                      v-model="month"
                      type="month"
                      :auto-submit="true"
                      :min="year == currentYear ? currentMonth : ''"
                      @change="getNumberOfDays()"
                    >
                    </date-picker>

                    <div class="fit-content pa-5">
                      <div>
                        <div
                          v-for="(day, index) in weekDays"
                          :key="index"
                          class="weekday-box d-inline-block blue-grey--text text--darken-3"
                          style="transform: rotate(-50deg)"
                        >
                          {{ day }}
                        </div>
                      </div>

                      <div
                        v-for="(number, index) in numberOfDay"
                        :key="index"
                        class="day-box d-inline-block"
                        :class="
                          checkDate(number)
                            ? computedDayColor(number)
                            : 'disabled'
                        "
                        :style="
                          index == 0
                            ? 'margin-right:' +
                              (deviceType == 'mobile' ? 60 : 57) *
                                numberOfWeekDay.value +
                              'px;'
                            : ''
                        "
                        @click="selectDate(number)"
                      >
                        <div
                          class="semi-number"
                          v-if="isSemi(number) && checkDate(number)"
                        >
                          <span>{{ computedLeftTimes(number) }}</span>
                        </div>
                        {{ number }}
                      </div>
                    </div>
                  </div>

                  <!--<ul class="ps-1">
                    <li class="h3 green--text">
                      کلیه زمان‌های ثبت شده در این روز آزاد هستند.
                    </li>
                    <li class="h3 orange--text">
                      در این روز کلیه زمان‌‌ها پر نشده‌اند.
                    </li>
                    <li class="h3 red--text">
                      کلیه زمان‌های ثبت شده در این روز پر هستند.
                    </li>
                  </ul>-->
                </v-col>

                <transition name="fade">
                  <v-col v-if="showTimes" lg="5" md="12" sm="12" cols="12">
                    <v-card class="date-result pa-6">
                      <div class="d-flex">
                        <p class="font-weight-bold primary--text">
                          {{
                            " زمان‌های پزشک در تاریخ" +
                            " " +
                            this.day +
                            " " +
                            persianMonthName(this.selectedMonth) +
                            " " +
                            this.selectedyear
                          }}
                        </p>
                        <div v-if="schedules.length" class="ps-2">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                style="font-size: 26px !important"
                                @click="colorsGuidance = true"
                                class="dark--text pointer"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-information-outline</v-icon
                              >
                            </template>
                            <span class="white--text"> راهنمای رنگ‌ها </span>
                          </v-tooltip>
                        </div>
                      </div>

                      <div v-if="timeLoading" class="w-100 text-center pa-5">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                        <h5 class="primary--text mt-3">در حال بارگذاری</h5>
                      </div>
                      <div
                        class="times-box py-3 text-center"
                        v-else-if="!timeLoading"
                      >
                        <span
                          class="m-2"
                          v-for="(item2, index2) in allTimes"
                          :key="index2"
                        >
                          <v-btn
                            elevation="0"
                            :class="
                              item2.status == 'catched' ||
                              item2.status == 'busy' ||
                              item2.status == 'passed'
                                ? 'disabled'
                                : ''
                            "
                            :color="
                              item2.status == 'free'
                                ? 'green lighten-2'
                                : item2.status == 'catched'
                                ? 'red lighten-2'
                                : item2.status == 'passed'
                                ? 'grey'
                                : 'yellow darken-2'
                            "
                            class="py-5 mb-2"
                            style="
                              border-radius: 7px;
                              width: 160px;
                              cursor: default;
                            "
                          >
                            <span class="text-light">{{ item2.time }}</span>
                            <div
                              @click="openRemoveModal(item2)"
                              v-if="item2.status == 'free'"
                              style="position: absolute; left: -10px"
                              class="pe-0"
                            >
                              <v-icon
                                style="cursor: pointer"
                                class="ms-2 mb-1 red--text text--lighten-1 delete-icon"
                                >mdi-delete-circle-outline</v-icon
                              >
                            </div>
                          </v-btn>
                        </span>
                      </div>
                    </v-card>
                  </v-col>
                </transition>
              </v-row>
            </v-card>
            <v-dialog v-model="colorsGuidance" width="500px">
              <v-card class="pa-5" style="border-radius: 20px">
                <v-card-title>
                  <span class="mx-auto c-primary"> راهنمای رنگ‌ها </span>
                </v-card-title>
                <v-container class="text-right">
                  <v-row class="px-5">
                    <v-col>
                      <ul class="h2">
                        <li class="green--text text--lighten-1 pb-3">
                          زمان‌ آزاد
                        </li>
                        <li class="red--text text--darken-1 pb-3">
                          زمان ملاقات ثبت شده
                        </li>
                        <li class="grey--text">زمان گذشته</li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
            <v-dialog v-model="addFreeDialog" width="700px">
              <v-card style="border-radius: 20px" class="px-5 py-3">
                <v-card-title class="ps-2">
                  <span class="h1"> افزودن زمان آزاد</span>
                </v-card-title>
                <v-card-text class="p-0">
                  <v-container>
                    <!-- inja -->
                    <v-row>
                      <v-col>
                        <span id="freeDateFrom">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="freeDateFrom"
                            :editable="true"
                            class="date-input"
                            label="تاریخ از"
                          >
                          </v-text-field>
                        </span>
                        <date-picker
                          v-model="freeDateFrom"
                          element="freeDateFrom"
                          color="#00a7b7"
                          :min="currentDate"
                        />
                      </v-col>
                      <v-col>
                        <span id="freeDateTo">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="freeDateTo"
                            :editable="true"
                            class="date-input"
                            label="تاریخ تا"
                          >
                          </v-text-field>
                        </span>
                        <date-picker
                          v-model="freeDateTo"
                          element="freeDateTo"
                          color="#00a7b7"
                          :min="currentDate"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span id="freeTimeFrom">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="freeTimeFrom"
                            :editable="true"
                            class="date-input"
                            label="ساعت از"
                          >
                          </v-text-field>
                        </span>
                        <date-picker
                          v-model="freeTimeFrom"
                          element="freeTimeFrom"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                      <v-col>
                        <span id="freeTimeTo">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="freeTimeTo"
                            :editable="true"
                            class="date-input"
                            label="ساعت تا"
                          >
                          </v-text-field>
                        </span>
                        <date-picker
                          v-model="freeTimeTo"
                          element="freeTimeTo"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <div class="ms-auto">
                    <v-btn
                      class="mb-2 primary-btn"
                      :loading="isBusy"
                      @click="addNew()"
                      >ثبت
                    </v-btn>

                    <v-btn
                      class="red-btn mb-2 mr-2 text-light"
                      @click="addFreeDialog = false"
                      >لغو
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showResultDialog" width="600">
              <v-card style="border-radius: 20px" class="modal-card px-3 py-3">
                <v-card-title>
                  <span class="common-text primary--text"
                    >نتیجه افزودن زمان آزاد</span
                  >
                </v-card-title>
                <v-card-text class="pa-5">
                  <v-container>
                    <v-row>
                      <v-col class="h3 font-weight-bold primary--text"
                        >انجام شده: {{ result.done }}</v-col
                      >
                      <v-col class="h3 font-weight-bold primary--text"
                        >انجام نشده: {{ result.undone }}</v-col
                      >
                    </v-row>
                    <hr />
                    <div
                      v-for="(item, index) in result.newResults"
                      :key="index"
                    >
                      <v-row>
                        <v-col class="h3">زمان آغاز:</v-col>
                        <v-col class="h3">{{ item.startTime }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col class="h3">زمان پایان:</v-col>
                        <v-col class="h3">{{ item.finishTime }}</v-col>
                      </v-row>
                      <v-row>
                        <v-col class="h3">وضعیت:</v-col>
                        <v-col class="h3">{{ item.text }}</v-col>
                      </v-row>
                      <hr />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <div class="ms-auto">
                    <v-btn
                      color="primary-btn"
                      @click="showResultDialog = false"
                    >
                      <v-icon class="me-1">mdi-close-outline</v-icon>
                      بستن
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="removeFreeDialog" max-width="600">
              <v-card class="py-3" style="border-radius: 20px">
                <v-card-title>
                  <span class="h1">حذف زمان آزاد</span>
                </v-card-title>
                <v-card-text class="px-5 pb-0">
                  <v-container>
                    <v-row>
                      <v-col>
                        <h5 class="text-center">
                          آیا از حذف زمان آزاد به ساعت
                          {{ selected.time }}
                          اطمینان دارید؟
                        </h5>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <div class="ms-auto">
                    <v-btn
                      class="mb-2 me-2 text-light primary-btn"
                      @click="removeFreeDialog = false"
                    >
                      خیر
                    </v-btn>
                    <v-btn
                      class="mb-2 text-light red-btn"
                      :loading="isBusy"
                      @click="removeFree()"
                      >بله
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <!-- لیست مرخصی‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- person leave list part has been componented -->
              <person-leave-list
                :leaves="leavesList"
                @getInfo="showDocInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست پرداخت‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- person payment part has been componented -->
              <person-payments
                :payments="paymentsList"
                @getInfo="showDocInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست دریافت ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- person receives part has been componented -->
              <person-receives
                :receives="receivesList"
                @getInfo="showDocInfo()"
              />
            </v-card>
          </v-tab-item>

          <!-- تسویه حساب -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <person-checkout
                :userData="userData.user"
                :checkOut="checkOut"
                :job="'دکتر'"
                @getInfo="showDocInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- فیش حقوقی -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- person payment part has been componented -->
              <person-pay-slip
                :paySlips="paySlips"
                :userData="userData.user"
                @getInfo="showDocInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- معاینات استخدام -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- person payment part has been componented -->
              <employment-tests
                :userData="userData.user"
                @getInfo="showDocInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست فایلها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- files part has been componented -->
              <person-files :files="filesList" @getInfo="showDocInfo()" />
            </v-card>
          </v-tab-item>
          <!-- تغییر رمز عبور-->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 700px">
              <!-- change password part has been componented -->
              <person-change-password @getInfo="showDocInfo()" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const EditPerson = () => import("@/components/hr/EditPerson");
const personLeaveList = () => import("@/components/hr/personLeaveList");
const PersonCheckout = () => import("@/components/hr/PersonCheckout");
const PersonFiles = () => import("@/components/hr/PersonFiles");
const PersonPayments = () => import("@/components/hr/PersonPayments");
const PersonReceives = () => import("@/components/hr/PersonReceives");
const PersonPaySlip = () => import("@/components/hr/PersonPaySlip");
const personActivityTime = () => import("@/components/hr/personActivityTime");
const personActivity = () => import("@/components/hr/personActivity");
const personInfo = () => import("@/components/hr/PersonInfo");
const personChangePassword = () => import("@/components/hr/PersonChangePass");
const EmploymentTests = () => import("@/components/hr/EmploymentTests");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    EditPerson,
    personLeaveList,
    PersonCheckout,
    PersonFiles,
    PersonPayments,
    PersonReceives,
    personActivityTime,
    personActivity,
    personInfo,
    personChangePassword,
    PersonPaySlip,
    EmploymentTests,
  },
  data() {
    return {
      tab: null,
      opens: [],
      isMounted: false,
      monthDict: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      moment: moment,
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      userData: [],
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      incomeCurrentPage: 1,
      incomePerPage: 10,
      incomeTotalRows: "",
      Filter: "",
      Busy: true,
      busyDocPercent: false,
      busyDocIncome: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
        { key: "share", label: "سهم پزشک" },
      ],
      excelFields: [
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
        { key: "share", label: "سهم پزشک" },
      ],
      Items: [],
      paymentFields: [
        { key: "item", label: "آیتم پرداختی" },
        { key: "payment", label: "مبلغ پرداختی" },
      ],
      income: {},
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      pageLoaded: false,
      leavesList: [],
      filesList: [],
      paymentsList: [],
      receivesList: [],
      checkOut: {},
      deviceType: "",
      userId: "",
      showMyCalendar: true,
      startDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      endDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      daysOfWeek: [
        {
          text: "یکشنبه",
          value: 1,
        },
        {
          text: "دوشنبه",
          value: 2,
        },
        {
          text: "سه‌شنبه",
          value: 3,
        },
        {
          text: "چهارشنبه",
          value: 4,
        },
        {
          text: "پنجشنبه",
          value: 5,
        },
        {
          text: "جمعه",
          value: 6,
        },
        {
          text: "شنبه",
          value: 0,
        },
      ],
      currentYear: moment(new Date()).format("jYYYY"),
      currentMonth: moment(new Date()).format("jMM"),
      year: moment(new Date()).format("jYYYY"),
      month: moment(new Date()).format("jMM"),
      day: moment(new Date()).format("jDD"),
      selected: {},
      selectedyear: "",
      selectedMonth: "",
      numberOfDay: 0,
      numberOfWeekDay: 0,
      allDates: [],
      allTimes: [],
      showTimes: false,
      schedules: [],
      colorsGuidance: false,
      isBusy: false,
      freeDateFrom: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeDateTo: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeTimeFrom: moment(new Date()).format("HH:mm"),
      freeTimeTo: moment(new Date()).add(2, "hours").format("HH:mm"),
      addFreeDialog: false,
      showResultDialog: false,
      result: {},
      removeFreeDialog: false,
      timeLoading: false,
      calendarInfo: false,
      paySlips: [],
      tests: [],
      testsFiles: [],
    };
  },
  computed: {
    incomeFields() {
      if (this.userData.user.expertise == "عمومی") {
        return [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "visitIncome", label: "مبلغ کارکرد ویزیت" },
          { key: "baliniIncome", label: "مبلغ کارکرد خدمات پرستاری" },
          { key: "surgeryIncome", label: "مبلغ کارکرد خدمات پزشک" },
          { key: "totalIncome", label: "جمع کارکرد" },
        ];
      } else if (this.userData.user.expertise == "دندانپزشک") {
        return [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "dentistryIncome", label: "مبلغ کارکرد دندانپزشک" },
          { key: "totalIncome", label: "جمع کارکرد" },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "expertVisitIncome", label: "مبلغ کارکرد ویزیت" },
          { key: "expertClinicalIncome", label: "مبلغ کارکرد خدمات" },
          { key: "totalIncome", label: "جمع کارکرد" },
        ];
      }
    },
    yearMonthComputed() {
      if (
        this.monthDict[moment(this.startDate).format("MM") - 1] ==
          this.monthDict[moment(this.endDate).format("MM") - 1] &&
        moment(this.startDate).format("YYYY") ==
          moment(this.endDate).format("YYYY")
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedDay() {
      let selected = "";
      this.schedules.forEach((day) => {
        let stringDay = JSON.stringify(day);
        if (stringDay.includes("انتخاب شده")) {
          selected = day;
        }
      });
      return selected;
    },
    requestedTimes() {
      if (this.selectedDay) {
        let selectedTimes = this.selectedDay.times.filter(
          (x) => x.status == "انتخاب شده"
        );
        return selectedTimes;
      } else {
        return [];
      }
    },
    weekDays() {
      return [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه‌شنبه",
        "چهارشنبه",
        "پنجشنبه",
        "جمعه",
      ];
    },
    checkPassedDate() {
      let passedDates = [];
      this.allDates.length
        ? this.allDates.forEach((x) => {
            x >= moment(new Date()).format("jYYYY/jMM/jDD")
              ? passedDates.push(x)
              : "";
          })
        : "";
      return passedDates;
    },
  },
  methods: {
    computedDayColor(number) {
      let chooseColor;
      if (number < 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/0" + number
        )[0].color;
      } else if (number >= 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/" + number
        )[0].color;
      }

      if (chooseColor == "none") {
        return "freeTime";
      } else if (chooseColor == "all") {
        return "busyTime";
      } else if (chooseColor == "semi") {
        return "semiTime";
      }
    },
    isSemi(number) {
      let chooseColor;
      if (number < 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/0" + number
        )[0]
          ? this.schedules.filter(
              (x) => x.date == this.year + "/" + this.month + "/0" + number
            )[0].color
          : "";
      } else if (number >= 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/" + number
        )[0]
          ? this.schedules.filter(
              (x) => x.date == this.year + "/" + this.month + "/" + number
            )[0].color
          : "";
      }

      if (chooseColor == "semi") {
        return true;
      } else {
        return false;
      }
    },
    computedLeftTimes(number) {
      let times;
      if (number < 10) {
        times = this.schedules
          .filter(
            (x) => x.date == this.year + "/" + this.month + "/0" + number
          )[0]
          .times.filter((y) => y.status == "free");
      } else if (number >= 10) {
        times = this.schedules
          .filter(
            (x) => x.date == this.year + "/" + this.month + "/" + number
          )[0]
          .times.filter((y) => y.status == "free");
      }
      return times.length;
    },
    docPercent() {
      this.Busy = true;
      this.busyDocPercent = true;
      this.busyDocIncome = true;
      this.docRequests();
      this.docIncome();
    },

    visibilityChanged() {
      setTimeout(() => {
        this.opens = [0, 1];
      }, 2000);
    },

    docRequests() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/financial/doctorIdPercent",
          {
            userId: this.$route.params.id,
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.busyDocPercent = false;
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
            this.Busy = false;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
            this.busyDocPercent = false;
            this.vloading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
          this.busyDocPercent = false;
          this.vloading = false;
        });
    },
    docIncome() {
      this.isMounted = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/financial/doctorIdIncome",
          {
            userId: this.$route.params.id,
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.busyDocIncome = false;
            this.income = res.data;

            this.incomeTotalRows = this.income.shift.length;
            this.incomeCurrentPage = 1;
            this.Busy = false;
            this.isMounted = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
            this.busyDocIncome = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
          this.busyDocPercent = false;
        });
    },
    docVisits() {
      this.isMounted = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/doctorAllVisitTimes",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            //doctor scheduls
            this.schedules = res.data;
            var currentDate = moment(new Date()).format("jYYYY/jMM/jDD");
            var currentTime = moment(new Date()).format("HH:mm");
            setTimeout(() => {
              this.schedules.forEach((x) => {
                if (x.date == currentDate) {
                  x.times.forEach((y) => {
                    let startTime = y.time.split(" - ")[0];
                    startTime < currentTime && y.status != "catched"
                      ? (y.status = "passed")
                      : "";
                  });
                }
              });

              this.schedules.forEach((x) => {
                this.allDates.push(x.date);
              });
              this.isMounted = true;
            }, 300);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    print(div) {
      //NOTE making pdf of div elements
      this.$htmlToPaper(div);
    },
    showDocInfo() {
      //NOTE getting doc infos when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/getInfo",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.userData.user = res.data.user;
            this.leavesList = res.data.leaves;
            this.filesList = res.data.files;
            this.paymentsList = res.data.payments;
            this.receivesList = res.data.receives;
            this.checkOut = res.data.checkOut;
            this.paySlips = res.data.payslips;

            this.vLoading = false;
            setTimeout(() => {
              this.pageLoaded = true;
            }, 500);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    incomeonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.incomeTotalRows = filteredItems.length;
      this.incomeCurrentPage = 1;
    },
    selectDate(number) {
      if (number < 10) {
        this.day = "0" + number;
      } else {
        this.day = number;
      }
      this.selectedyear = this.year;
      this.selectedMonth = this.month;

      this.allTimes = this.schedules.filter(
        (x) => x.date == this.year + "/" + this.month + "/" + this.day
      )[0].times;
      this.showTimes = true;
    },
    persianMonthName(month) {
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return monthName;
    },
    getNumberOfDays() {
      if (1 <= parseInt(this.month) && parseInt(this.month) < 7) {
        this.numberOfDay = 31;
      } else if (7 <= parseInt(this.month) && parseInt(this.month) != 12) {
        this.numberOfDay = 30;
      } else if (parseInt(this.month) == 12) {
        let isLead = moment(this.year + "/12/30", "jYYYY/jMM/jDD").isValid();
        if (isLead) {
          this.numberOfDay = 30;
        } else this.numberOfDay = 29;
      }

      if (parseInt(this.day) > parseInt(this.numberOfDay)) {
        this.day = this.numberOfDay;
      }
      let date = this.year + "/" + this.month + "/" + "01";

      let day = moment(date, "jYYYY/jMM/jDD").format("d");
      this.numberOfWeekDay = this.daysOfWeek[day];

      // alert(this.numberOfWeekDay);
      // alert(this.numberOfDay);
    },
    checkDate(number) {
      if (number < 10) {
        if (
          this.checkPassedDate.includes(
            this.year + "/" + this.month + "/0" + number
          )
        ) {
          return true;
        } else return false;
      } else {
        if (
          this.checkPassedDate.includes(
            this.year + "/" + this.month + "/" + number
          )
        ) {
          return true;
        } else return false;
      }
    },

    showNow() {
      this.year = this.currentYear;
      this.month = this.currentMonth;
      this.getNumberOfDays();
    },
    openRemoveModal(item) {
      this.selected = item;
      this.removeFreeDialog = true;
    },
    addNew() {
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/newVisitTime",
          {
            userId: this.$route.params.id,
            startDate: this.freeDateFrom + this.freeTimeFrom,
            endDate: this.freeDateTo + this.freeTimeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 201) {
            this.addFreeDialog = false;

            this.freeDateFrom = moment(new Date()).format("jYYYY-jMM-jDD");
            this.freeDateTo = moment(new Date()).format("jYYYY-jMM-jDD");
            this.freeTimeFrom =
              moment(new Date().getHours()) +
              ":" +
              moment(new Date().getMinutes());
            this.freeTimeTo = moment(new Date())
              .add(2, "hours")
              .format("HH:mm");
            this.toast("زمان مورد نظر با موفقیت به ثبت رسید.", "success");
            this.result = res.data;
            this.showResultDialog = true;
            this.docVisits();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    removeFree() {
      this.vLoading = true;
      this.timeLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/deleteVisitTime",
          {
            visitTimeId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.removeFreeDialog = false;
            this.docVisits();
            setTimeout(() => {
              this.allTimes = this.schedules.filter(
                (x) => x.date == this.year + "/" + this.month + "/" + this.day
              )[0].times;
              this.timeLoading = false;
            }, 500);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.timeLoading = false;
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.timeLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },

  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    let previousPage = localStorage.getItem("previousPage");
    localStorage.removeItem("previousPage");
    if (previousPage == "newPaySlip") {
      this.tab = 10;
    }
    this.userId = this.$route.params.id;
    this.docPercent();
    this.docVisits();
    this.showDocInfo();
    this.getNumberOfDays();
  },
};
</script>
<style lang="scss">
.modal-card {
  max-height: 580px;
  overflow-y: scroll;
}
@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-tabs-slider {
  display: none;
}

.calendar {
  user-select: none;
  border-radius: 17px !important;
  float: right;
  width: 430px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%);

  .header {
    background-color: #00a7b7;
    padding: 33px 0;
    border-radius: 17px 17px 0 0 !important;
    position: relative;
    .calendar-info {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 200;
    }
    .calendar-btn {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 200;
      .v-btn {
        border-radius: 8px;
        span {
          font-weight: bold;
        }
      }
    }
  }
  .month {
    color: #98aaca;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #00a7b7;
    width: 85%;
    margin: auto;
  }
  .day-box {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 4px;
    text-align: center;
    border-radius: 50%;
    padding-top: 13px;
    font-size: 1.5rem;
    margin-right: 6px;
    &:hover {
      background-color: #eeeeee;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: none;
    }
    &.freeTime {
      background-color: #00695daf;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
    &.busyTime {
      background-color: #df6a6ac2;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    &.semiTime {
      background-color: #f7bf50d9;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    .semi-number {
      position: absolute;
      top: -5px;
      right: -3px;
      background-color: #e33838;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
        font-size: 10px;
      }
    }
  }
  .weekday-box {
    width: 55px;
    height: 48px;
    text-align: center;
    border-radius: 50%;
    padding-top: 6px;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.date-result {
  border-radius: 17px !important;
  background-color: rgba(243, 243, 243, 0.482) !important;
  .delete-icon:hover {
    color: red !important;
  }
  .times-box {
    border-radius: 5px !important;
    border: 1px solid rgb(221, 221, 221);
    height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 10px;
      margin: 0 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgb(236, 236, 236);
      opacity: 0.5;
      border-radius: 1px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(185, 185, 185);
      width: 10px;
      border-radius: 1px !important;
    }
  }
  .selected-date {
    &:hover {
      color: #94afd8;
      // text-decoration: underline;
    }
  }
}
@media only screen and (max-width: 600px) {
  .calendar {
    width: 270px;
    float: none;
    margin: 0 auto !important;

    .day-box {
      width: 30px;
      height: 30px;
      padding-top: 7px;
      margin: 1px;
    }
    .weekday-box {
      width: 32px;
      height: 32px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
</style>
